<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
    .info
      //- img.logo(alt="Vue logo" src="../assets/banner.png")
      h2.title 手指鼓大賽
      h2.title Finger Drumming Play
      h4.title-sub 2023 臺灣文博會
      h3 請選擇您喜歡的角色
      hr
      
    //- el-button(@click='start') 確認

    el-card.clickCard(shadow='hover' v-show='clickVisible')
      h2.title 手指鼓大賽
      h2.title Finger Drumming Play
      h4.title-sub 2023 臺灣文博會
      h3 請跟從主持人的引導開始點擊！
      .bt
        el-button.clickButton(v-show='this.selected.color==="#ff0000"' @click='sendClick' type="danger" plain ) {{ clickText }}
        el-button.clickButton(v-show='this.selected.color==="#00ff00"' @click='sendClick' type="success" plain ) {{ clickText }}
      img(:src='"../assets/img/" + this.selected.index + ".jpg"')

    .d-flex.outer(v-show='characterVisible')
      .box(v-for='(character, index) in this.characters' :key='index' v-show='syncArray[index]===1')
        .selectedMask
          img.character(@click='sendCharacter(character)' :src='"../assets/img/" + character.index + ".jpg"')

</template>

<script>
// @ is an alias to /src
// import * as Tone from 'tone'

export default {
  name: 'Home',
  components: {
    // Tone,
  },
  data() {
    return {
      socket: '',
      loading: false,
      checkVisible: true,
      infoVisible: true,
      timeout: null,
      currentTime: 0,
      bClick: false,
      clickText: '點我',
      characterVisible: true,
      clickVisible: false,
      selected: {index: 1,},
      syncArray: [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,],
      characters: [{
          index: 1,
          clickMsg: 'w1',
          selectMsg: 'sw1',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 2,
          clickMsg: 'w2',
          selectMsg: 'sw2',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 3,
          clickMsg: 'w3',
          selectMsg: 'sw3',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 4,
          clickMsg: 'w4',
          selectMsg: 'sw4',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 5,
          clickMsg: 'w5',
          selectMsg: 'sw5',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 6,
          clickMsg: 'w6',
          selectMsg: 'sw6',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 7,
          clickMsg: 'w7',
          selectMsg: 'sw7',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 8,
          clickMsg: 'w8',
          selectMsg: 'sw8',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 9,
          clickMsg: 'w9',
          selectMsg: 'sw9',
          beSelected: false,
          color: '#00ff00'
        },
        {
          index: 10,
          clickMsg: 'w10',
          selectMsg: 'sw10',
          beSelected: false,
          color: '#00ff00'
        },
        {
          index: 11,
          clickMsg: 'w11',
          selectMsg: 'sw11',
          beSelected: false,
          color: '#00ff00'
        },
        {
          index: 12,
          clickMsg: 'w12',
          selectMsg: 'sw12',
          beSelected: false,
          color: '#00ff00'
        },
        {
          index: 13,
          clickMsg: 'w13',
          selectMsg: 'sw13',
          beSelected: false,
          color: '#ff0000'
        },
        {
          index: 14,
          clickMsg: 'w14',
          selectMsg: 'sw14',
          beSelected: false,
          color: '#00ff00'
        },
        {
          index: 15,
          clickMsg: 'w15',
          selectMsg: 'sw15',
          beSelected: false,
          color: '#00ff00'
        },
        {
          index: 16,
          clickMsg: 'w16',
          selectMsg: 'sw16',
          beSelected: false,
          color: '#00ff00'
        },
      ],
    }
  },
  watch: {
  },
  computed: {

  },
  created() {
    // this.id = this.$route.query.id;
    // this.passcode = this.$route.query.passcode;
    // console.log('query :>> ', this.id, this.passcode);
  },
  mounted () {
    
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:7070', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://finger-drumming-play.clab.org.tw:7070', {
      withCredentials: true,
      extraHeaders: {
        'finger-drumming-play': 'abcd',  
      },
      query: {
        'client': 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
      this.socket.emit('checkCharacter');
    });

    this.socket.on('allMessage', (data) => {
      console.log('allMessage', data);
    });

    this.socket.on('syncSelected', (array) => {
      this.syncArray = array;
      console.log('syncSelected', this.syncArray);
    });

    this.socket.on('reset', () => {
      this.syncArray.fill(1);
      console.log('reset', this.syncArray);
      this.clickVisible = false;
    });

  },
  destroyed() {
    this.socket.disconnect();
  },
  methods: {
    
    start() {
      this.infoVisible = false;
      // const src = '/video/ttls-b.mp4';
      // this.playVideo(src);
      this.socket.emit('onMobileConnected');
      setTimeout(() => {
        // this.player.pause();
        // this.player.currentTime = this.currentTime;
        // this.player.play();
        console.log('currentTime :>> ', this.player.currentTime);  
      }, 500);
    },

    sendCharacter(obj) {
      this.selected = obj;
      this.characterVisible = false;
      this.clickVisible = true;
      this.scrollToTop();
      this.socket.emit('sendCharacter', this.selected.index);
      console.log('sendCharacter :>> ', this.selected.index);
    },

    sendClick() {
      this.bClick = !this.bClick;
      if(this.bClick){
        this.clickText = "CLICK"
      }else{
        this.clickText = "點我"
      }
      this.socket.emit('sendClick', this.selected.index);
      console.log('sendClick :>> ', this.selected.index);
    },

    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
}
</script>

<style lang='scss' scoped>
.container {
  // background-image: url('/img/bg.jpeg');
  background-color: white;
  height: auto;
  // min-height: 200vh;
  // width: 100vw;
  padding: 30px;
  color: black;

  
  .logo {
    width: 100%;
  }

  .title {
    color: black;
    margin: 0px;
  }

  .title-sub{
    color: black;
    margin-bottom: 20px;
  }

  .check {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
  }

  .el-card {
    margin-top: 20px;
    background-color: rgb(0 0 0 / 0%);
    border: 1px solid rgb(0 0 0 / 0%);
    color: white;
  }

  .d-flex{
    // display: flex;
    // background-color: black;
    height: auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 50% 50%;
    grid-column: 2;
    margin: 0 auto;
  }

  .outer{
      /* background-color: orange; */
      padding: 15px;
      height: 150px;
      margin: -28px;
  }

  .box{
    background-color: rgb(255, 255, 255);
    // flex:1;
    border-radius: 10px;
    margin:5px;
    padding: 10px;
  }

  .character {
    // border-radius: 200px;
    width: 90%;
  }

  .clickCard{
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    color: black;
    .bt{
      margin-top: 2vh;
    }

    img {
      margin-top: 5vh;
      border-radius: 10px;
      width: 30vw;
    }
  }

  .clickButton{
    width: 65vw;
    height: 65vw;
    font-size: 4em;
    border-radius: 20px;
  }
  .fade-enter-active, .fade-leave-active {
  transition: opacity .05s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>