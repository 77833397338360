import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

import VModal from 'vue-js-modal';
import VideoPlayer from 'vue-videojs7';

import NoSleep from 'nosleep.js/dist/NoSleep.min.js';
Vue.prototype.$NoSleep = NoSleep;

Vue.config.productionTip = false

Vue.use(VModal, { dynamicDefaults: { height: 'auto' } }).use(VideoPlayer);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
